<template>
    <div class="task_tab_info">
        <div 
            v-if="task.reason" 
            class="mb-4">
            <template v-if="task.reason.model === 'chat_message'">
                {{$t('task.main_task_message')}}
                <router-link :to="{name: 'chat', query:{id: task.reason.chat}}">
                    {{task.reason.chat}}
                </router-link>
            </template>
            <template v-if="task.reason.model === 'comments'">
                {{$t('task.main_task_comment')}}
                {{task.reason.id}}
            </template>
            <template v-if="task.reason.model === 'files'">
                {{$t('task.main_task_files')}}
                {{task.reason.id}}
            </template>
        </div>
        <template v-if="isMobile">
            <div class="py-2 border-b border-gray-200">
                {{ task.name }}
            </div>
            <!-- Дата начала -->
            <div 
                v-if="asideSetting.date_start_plan && task.date_start_plan" 
                class="py-2 border-b border-gray-200">
                <div class="mb-1 text-sm font-semibold">
                    {{ asideSetting.date_start_plan.label }}
                </div>
                <div>{{$moment(task.date_start_plan).format('DD.MM.YYYY в HH:mm')}}</div>
            </div>

            <!-- Дата старта -->
            <div 
                v-if="task.date_start_fact" 
                class="py-2 border-b border-gray-200">
                <div class="mb-1 text-sm font-semibold">
                    {{$t('task.date_start_fact')}}
                </div>
                <div>{{$moment(task.date_start_fact).format('DD.MM.YYYY в HH:mm')}}</div>
            </div>

            <!-- Крайний срок -->
            <div 
                v-if="asideSetting.showDeadline" 
                class="py-2 border-b border-gray-200">
                <div class="mb-1 text-sm font-semibold">
                    {{$t('task.dead_line')}}
                </div>
                <DeadLine 
                    :taskStatus="task.status" 
                    :date="task.dead_line" />
            </div>

            <!-- Приоритет -->
            <div class="py-2 border-b border-gray-200">
                <div class="mb-1 text-sm font-semibold">
                    {{$t('task.priority')}}
                </div>
                <div class="flex items-center">
                    <a-badge :status="priorityCheck.color" />
                    {{priorityCheck.name}}
                </div>
            </div>

            <!-- Статус -->
            <div class="py-2 border-b border-gray-200">
                <div class="mb-1 text-sm font-semibold">
                    {{$t('task.status')}}
                </div>
                <TaskStatus :status="task.status" />
            </div>

            <!-- Проект -->
            <div 
                v-if="task.project && asideSetting.project" 
                class="py-2 border-b border-gray-200">
                <div class="mb-1 text-sm font-semibold">
                    {{$t('task.project')}}
                </div>
                <div 
                    class="flex items-center cursor-pointer" 
                    @click="openWorkgroup('viewGroup', task.project)">
                    <div>
                        <a-avatar 
                            :src="workgroupLogoPath(task.project)" 
                            icon="team" 
                            :size="32" />
                    </div>
                    <span class="ml-2">{{task.project.name}}</span>
                </div>
            </div>

            <!-- Группа -->
            <div 
                v-if="task.workgroup && asideSetting.workgroup" 
                class="py-2 border-b border-gray-200">
                <div class="mb-1 text-sm font-semibold">
                    {{$t('task.workgroup')}}
                </div>
                <div 
                    class="flex items-center cursor-pointer" 
                    @click="openWorkgroup('viewProject', task.workgroup)">
                    <div>
                        <a-avatar 
                            :src="workgroupLogoPath(task.workgroup)" 
                            icon="team" 
                            :size="32" />
                    </div>
                    <span class="ml-2">{{task.workgroup.name}}</span>
                </div>
            </div>

            <!-- Спринт -->
            <div 
                class="py-2 border-b border-gray-200" 
                v-if="task.sprint || task.sprint_history.length > 0">
                <div class="mb-1 text-sm font-semibold">
                    {{$t('task.participant_sprint')}}
                </div>
                <a 
                    v-if="task.sprint" 
                    @click="openSprint(task.sprint.id)" 
                    class="flex items-center">
                    {{task.sprint.name }}
                </a>
                <div v-else-if="task.sprint_history.length > 0">
                    <a 
                        v-for="item in task.sprint_history" 
                        :key="item.id" 
                        @click="openSprint(item.id)" 
                        class="flex items-center">
                        {{item.name}}

                    </a>
                </div>
            </div>

            <!-- Автор -->
            <div 
                v-if="asideSetting.owner" 
                class="sidebar_item">
                <div>
                    <Profiler 
                        :user="task.owner"
                        initStatus
                        :subtitle="{text: asideSetting.owner ? asideSetting.owner.label : $t('task.owner')}" />
                </div>
            </div>

            <!-- Ответственный -->
            <div 
                v-if="asideSetting.operator" 
                class="sidebar_item">
                <div>
                    <template v-if="user && task.is_auction">
                        <div class="text-gray mb-1">
                            {{ asideSetting.operator ? asideSetting.operator.label : $t('task.operator') }}
                        </div>
                        <template v-if="isMobile">
                            <a-button
                                type="primary"
                                ghost
                                class="flex items-center"
                                :loading="takeLoader"
                                @click="mobileTakeTask(task)">
                                <i class="fi fi-rr-user-add mr-2"></i>
                                Взять
                            </a-button>
                        </template>
                        <a-popconfirm
                            v-else
                            title="Вы действительно хотите взять задачу?"
                            ok-text="Да"
                            cancel-text="Нет"
                            @confirm="takeTask(task)">
                            <a-button
                                type="primary"
                                ghost
                                class="flex items-center"
                                :loading="takeLoader">
                                <i class="fi fi-rr-user-add mr-2"></i>
                                Взять
                            </a-button>
                        </a-popconfirm>
                    </template>
                    <Profiler
                        v-else 
                        :user="task.operator"
                        initStatus
                        :subtitle="{
                            text: asideSetting.operator ? asideSetting.operator.label : $t('task.operator')
                        }" />
                </div>
            </div>

            <!-- Наблюдатели -->
            <div 
                v-if="asideSetting.visors && task.visors.length" 
                class="sidebar_item">
                <div 
                    v-for="user in task.visors" 
                    :key="user.id" 
                    class="visor_item">
                    <Profiler 
                        :user="user"
                        initStatus
                        :subtitle="{
                            text: asideSetting.visors ? asideSetting.visors.label : $t('task.visor')
                        }" />
                </div>
            </div>

            <!-- Дата создания -->
            <div 
                v-if="asideSetting.showCreated" 
                class="py-2 border-b border-gray-200">
                <div class="mb-1 text-sm font-semibold">
                    {{$t('task.created')}}
                </div>
                <div>{{$moment(task.created_at).format('DD.MM.YYYY в HH:mm')}}</div>
            </div>

        </template>
        <template v-if="task.description">
            <div :class="isMobile && 'py-2'">
                <div 
                    v-if="isMobile"
                    class="mb-1 text-sm font-semibold">
                    {{ $t('task.description') }}
                </div>
                <TextViewer 
                    class="body_text" 
                    :body="task.description" />
            </div>
        </template>
        <div 
            v-else
            class="flex items-center justify-center"
            :class="isMobile && 'py-2'">
            <a-empty :description="$t('task.no_description')">
                <a-button 
                    v-if="dropActions && dropActions.edit && dropActions.edit"
                    type="ui"
                    @click="edit()">
                    {{$t('task.add')}}
                </a-button>
            </a-empty>
        </div>
        <!-- Голосования -->
        <div 
            v-if="task.task_type === 'task'" 
            class="flex justify-end mt-2">
            <template v-if="!taskVoteLoading">
                <div class="text-lg">
                    <span>
                        <i 
                            @click="vote('like')"
                            class="fi fi-rr-social-network transition-colors cursor-pointer"
                            :class="{ 'blue_color': myVote === 'like'}"></i>
                        <span class="ml-1">{{ taskVote.likes_count }}</span>
                    </span>
                    <span class="ml-3">
                        <i 
                            @click="vote('dislike')"
                            class="fi fi-rr-hand transition-colors cursor-pointer"
                            :class="{ 'text_red': myVote === 'dislike'}"></i>
                        <span class="ml-1">{{ taskVote.dislikes_count }}</span>
                    </span>
                </div>
            </template>
            <template v-else>
                <a-spin></a-spin>
            </template>
        </div>

        <div 
            v-if="task.parent"
            class="mt-4 parent_task">
            <!-- Основная задача -->
            {{$t('task.main_task')}} 
            <span 
                class="cursor-pointer blue_color text-primary" 
                @click="openTask()">
                {{task.parent.name}}
            </span>
        </div>

        <template v-if="!isMobile">
            <!-- Таблица подзадач -->
            <template v-if="task.children_count > 0">
                <TaskList
                    class="border_gray_top"
                    :class="isMobile || 'pt-4 mt-4'"
                    :hash="false"
                    :showChildren="false"
                    :showAddButton="false"
                    :showPager="false"
                    autoHeight
                    :showHeader="false"
                    :reloadTask="reloadTask"
                    :queryParams="{parent: task.id}"
                    :scrollWrapper="{x: 1300, y: 300}"
                    size="small"
                    :pageName="`page_list_task_${task.id}.TaskModel`"
                    :taskType="task.task_type"
                    :showFilter="false"
                    :showActionButton="false"
                    hideActionColumn

                    :name="`fltask_${task.id}`" >
                    <template v-slot:header>
                        <h4 class="mb-3 text-lg font-semibold">
                            {{$t('task.subtask')}}
                        </h4>
                    </template>
                </TaskList>
            </template>
    
            <!-- Комментарии -->
            <div class="mt-5">
                <div class="mb-1 font-semibold">
                    Комментарии
                </div>
                <vue2CommentsComponent
                    bodySelector=".task_body_wrap"
                    :related_object="task.id"
                    model="tasks" />
            </div>
        </template>
        <template v-else>
            <div class="task_info_collapse mt-4">
                <a-collapse 
                    :bordered="false" 
                    v-model="expanded" >
                    <!-- Таблица подзадач -->
                    <a-collapse-panel
                        v-if="task.children_count > 0"
                        key="subtasks"
                        header="Подзадачи">
                        <TaskList
                            class="border_gray_top"
                            :class="isMobile || 'pt-4 mt-4'"
                            :hash="false"
                            :showHeader="false"
                            :showChildren="false"
                            :showAddButton="false"
                            :showPager="false"
                            :reloadTask="reloadTask"
                            :queryParams="{parent: task.id}"
                            :scrollWrapper="{x: 1000, y: 300}"
                            size="small"
                            :pageName="`page_list_task_${task.id}.TaskModel`"
                            :taskType="task.task_type"
                            :showFilter="false"
                            :showActionButton="false"
                            hideActionColumn
                            :name="`fltask_${task.id}`" >
                            <template v-slot:header>
                                <h4 class="mb-3 text-lg font-semibold">
                                    {{$t('task.subtask')}}
                                </h4>
                            </template>
                        </TaskList>
                    </a-collapse-panel>
                    
                    <!-- Комментарии -->
                    <a-collapse-panel
                        key="comments"
                        header="Комментарии">
                        <vue2CommentsComponent
                            bodySelector=".task_body_wrap"
                            :related_object="task.id"
                            injectContainer
                            :injectContainerSelector="bodyWrap"
                            model="tasks" />
                    </a-collapse-panel>
                </a-collapse>
            </div>
        </template>
    </div>
</template>

<script>
import TaskStatus from '../../TaskStatus'
import { priorityList } from '../../../utils'
import DeadLine from '../../DeadLine'
import TaskList from '../../TaskList/TaskList'
import vue2CommentsComponent from '@apps/vue2CommentsComponent'
import TextViewer from '@apps/CKEditor/TextViewer.vue'
import taskHandler from '../../mixins/taskHandler.js'
export default {
    mixins: [taskHandler],
    components: {
        TaskList,
        vue2CommentsComponent,
        DeadLine,
        TaskStatus,
        TextViewer
    },
    props: {
        task: {
            type: Object,
            default: () => null
        },
        reloadTask: {
            type: Function,
            default: () => {}
        },
        myTask: {
            type: Boolean,
            default: false
        },
        edit: {
            type: Function,
            default: () => {}
        },
        openTask: {
            type: Function,
            default: () => {}
        },
        dropActions: {
            type: Object,
            default: () => null
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        closeDrawer: {
            type: Function,
            default: () => {}
        },
        bodyWrap: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            priorityList,
            expanded: 'comments',
            taskVote: {},
            taskVoteLoading: false
        }
    },
    computed: {
        asideSetting() {
            return this.task.aside_settings ? this.task.aside_settings : null 
        },
        priorityCheck() {
            const find = this.priorityList.find(item => item.value === this.task.priority)
            if(find)
                return find
            else
                return null
        },
        user() {
            return this.$store.state.user.user
        },
        myVote() {
            if(this.taskVote.my_vote === null)
                return null
            if(this.taskVote.my_vote)
                return 'like'
            return 'dislike'
            
        }
    },
    created() {
        this.taskVoteLoading = true
        this.$http(`vote/${this.task.id}/`)
            .then(({ data }) => {
                this.taskVote = data
            })
            .catch(error => {
                console.error(error)
            })
            .finally(() => {
                this.taskVoteLoading = false
            }) 
    },
    methods: {
        openWorkgroup(type, item) {
            this.closeDrawer()
            this.$router.replace({
                query: { [type]: item.id }
            })
        },

        /** @param {string} choice принимает 'like' или 'dislike' */
        async vote(choice) {
            let boolChoice,
                fieldToVote,
                oppositeFieldToVote
            if (choice === 'like') {
                fieldToVote = 'likes_count'
                oppositeFieldToVote = 'dislikes_count'
                boolChoice = true
            } else if (choice === 'dislike') {
                fieldToVote = 'dislikes_count'
                oppositeFieldToVote = 'likes_count'
                boolChoice = false
            }
            const payload = {
                vote: boolChoice
            }

            await this.$http.post(`vote/${this.task.id}/`, payload)
                .then(() => {
                    if(this.taskVote.my_vote !== null) {
                        if(this.taskVote.my_vote === boolChoice) {
                            this.taskVote[fieldToVote] += -1
                            this.taskVote.my_vote = null
                        } else {
                            this.taskVote[oppositeFieldToVote] += -1
                            this.taskVote[fieldToVote] += 1
                            this.taskVote.my_vote = boolChoice
                        }
                    } else {
                        this.taskVote[fieldToVote] += 1
                        this.taskVote.my_vote = boolChoice
                    }
                })
                .catch(error => console.error(error))
        },
        workgroupLogoPath(workgroup) {
            return workgroup?.workgroup_logo?.path || ''
        },
    },

}
</script>

<style lang="scss" scoped>
.task_info_collapse {
    margin-left: -15px;
    margin-right: -15px;
    ::v-deep {
        .ant-collapse-header {
            font-weight: 500;
        }
    }
    
}
.body_text {
    overflow: auto;
}
</style>